import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PageService } from '../services/page.service';
import { catchError, map } from 'rxjs/operators';
import { PageModel } from '../models/page.models';
import { MetaService } from '../services/meta.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageResolver implements Resolve<PageModel | null> {
  private pageService: PageService = inject(PageService);
  private metaService = inject(MetaService);

  constructor(protected router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PageModel> | Observable<null> | PageModel {
    let slug: string = route.data['slug'] || null;

    const additions: string[] = route.data['additions'] ? route.data['additions'] : [];

    const exportSlug = this.getExportSlug(route);

    if (!slug && !exportSlug) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then();
      return of(null);
    }

    if (exportSlug) {
      slug = exportSlug;
    }

    let page = this.pageService.pages[slug];

    if (!page) {
      page = this.pageService.getPage(slug, additions).pipe(
        map(page => {
          this.metaService.setMeta(page?.translation?.meta, {
            title:
              ['main-page', 'general'].indexOf(page.slug) == -1
                ? page?.translation?.data?.title
                : null,
          });
          return page;
        }),
        catchError(() => {
          return of(null);
        })
      ) as Observable<PageModel>;
    }

    this.metaService.setMeta(page?.meta ? page.meta : page?.translation?.meta, {
      title:
        ['main-page', 'general'].indexOf(page.slug) == -1 ? page?.translation?.data?.title : null,
    });

    return page;
  }

  private getExportSlug(route: ActivatedRouteSnapshot): string | null {
    const pageExportSlug = route.data['pageExportSlug'] || null;
    const directionType = environment.direction.title;

    if (!pageExportSlug || !directionType) {
      return null;
    }
    return `${directionType}${pageExportSlug}`;
  }
}
