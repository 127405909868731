import { inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaModel } from '../models/meta.models';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  protected metaService = inject(Meta);
  protected titleService = inject(Title);

  setMeta(data: MetaModel | any, additional: any = null): void {
    let keys = [];

    if (data) {
      for (const key in data) {
        if (data[key]) {
          if (key === 'title') {
            this.titleService.setTitle(<string>data[key]);
          } else {
            const name = key.replace('_', ':');
            this.metaService.updateTag({
              name,
              content: data[key],
            });
          }
          keys.push(key);
        }
      }
    }
    let ogKeys = ['title', 'image', 'description'];
    for (let key of ogKeys) {
      let value = data?.[key] || null;
      if (additional?.[key] || value) {
        if (keys.indexOf(`og_${key}`) == -1) {
          this.metaService.updateTag({
            name: `og:${key}`,
            content: value ? value : additional[key],
          });
        }
        if (key == 'title' && keys.indexOf('title') == -1) {
          this.titleService.setTitle(value ? value : additional[key]);
        }
      }
    }
  }
}
